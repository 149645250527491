/* Base styles for both apps */
body {
  font-family: "Courier New", monospace;
  background-color: #000000;
  color: #fff;
  margin: 0;
  padding: 0;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-star; /* Changed to center for CryptoPrices, but can be adjusted */
  align-items: center;
}

/* Crypto Prices specific styles */
h1 {
  font-size: 24px;
  color: #fff; /* White text */
  margin: 20px 0 0 0;
  position: relative;
  z-index: 1;
  text-align: center;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  background-color: #000000;
  color: #fff;
  border: 1px solid #8a0000;
  margin: 10px 0;
  padding: 10px;
}

.arrow {
  font-size: 12px;
  float: right;
  margin-right: 5px;
  cursor: pointer;
}

/* Arrow for dropdown functionality in calculator */
.arrowCalc {
  font-size: 12px;
  position: absolute;
  top: 10%;
  transform: translateY(-50%);
  right: -20px;
}

.expanded-details {
  background-color: #000000;
  border: 1px solid #8a0000;
  padding: 10px;
}

.expanded-details p {
  margin: 5px 0;
}

/* Calculator specific styles */
.App {
  text-align: center;
  width: 100%;
  position: relative;
}

.App-logo {
  display: none;
}

.App-header {
  background-color: #000000; /* Black background */
  color: #fff;
  padding: 20px;
  width: 100%;
  max-width: 400px;
  border: none;
  position: relative;
  text-align: center;
}

h2 {
  font-size: 20px;
  color: #fff;
  cursor: pointer;
  margin: 20px 0 0 0;
  text-align: center;
  position: relative;
  display: inline-block;
}

.header-line {
  width: 100%;
  height: 1px;
  background-color: #8a0000;
  position: absolute;
  left: 0;
  top: 50px;
  z-index: 0;
}

.calculator-content {
  background-color: #000000;
  border: none;
  padding: 10px;
  text-align: left;
  margin-top: 10px;
}

.calculator-content div {
  margin: 10px 0;
}

label {
  display: block;
  text-align: left;
  margin-bottom: 5px;
}

input[type="number"] {
  background-color: #000000;
  color: #fff;
  border: 1px solid #8a0000;
  width: 100%;
  padding: 5px;
}

button {
  background-color: #5f6c79;
  color: #fff;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  margin-top: 10px;
}

button:hover {
  background-color: #8c9ba5;
}

span {
  color: #fff;
  display: block;
  margin-top: 10px;
}
